import React, { useState } from 'react';
import './CSS/App.css'; // You can style your components here
import productsData from './products.json';
import { Link } from 'react-router-dom';

const List = () => {
  const [wishlist, setWishlist] = useState([]);

  const addToWishlist = (car) => {
    setWishlist((prevWishlist) => [...prevWishlist, car]);
  };

  const removeFromWishlist = (index) => {
    setWishlist((prevWishlist) => {
      const updatedWishlist = [...prevWishlist];
      updatedWishlist.splice(index, 1);
      return updatedWishlist;
    });
  };

  return (
    <div className="App">
      <h1 style={{color:"white"}}>Product List</h1>
      <div className="product-list">
        <h2 style={{color:"white"}}>Cars from Turners</h2>
        <div>
        <Link to="/">
        <button style={{background:"blue"}}>BACK TO HOME</button>
      </Link>
        </div>
        <ul className="similar-cars-list">
          {productsData.map((car) => (
            <li key={car.id} className="similar-car-item">
              <div>
                <img style={{height:"200px" , width:"300px"}} src={car.image} alt={car.name} width={100} height={80} />
              </div>
              <div style={{
                padding: '7px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                fontSize: '14px',
                outline: 'none',
                backgroundColor: 'black',
                color: 'white'
              }}>
                <p>Name: {car.name}</p>
                <p>Color: {car.color}</p>
                <p>Amount: ${car.amount}</p>
                <button onClick={() => addToWishlist(car)}>Add to Wishlist</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Wishlist section */}
      <div className="wishlist-section" style={{ backgroundColor: 'lightgreen' }}>
        <h2 style={{ color: 'red' }}>
          Wishlist
        </h2>
        <ul>
          {wishlist.map((wishlistItem, index) => (
            <li key={index}>
              <img src={wishlistItem.image} alt={wishlistItem.name} width={100} height={80} />
              <p>Name: {wishlistItem.name}</p>
              <p>Color: {wishlistItem.color}</p>
              <p>Amount: ${wishlistItem.amount}</p>
              <button onClick={() => removeFromWishlist(index)}>Remove from Wishlist</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default List;
